import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import ServicesHeader from "./ServicesHeader";

export default class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service10',
          title: "Suministro de equipos para la seguridad industrial",
          description: 'Arnes, Eslingas, Cascos, Cuerdas, Líneas de vida, Eslingas posicionamiento,  Mosquetones, Sillas, Etc'
        },
        {
          id: 'service11',
          title: "Equipos para atención de emergencias",
          description: 'Botiquines, Guantes, Camillas, Kit inmovilizados.'
        },
        {
          id: 'service12',
          title: "Dotaciones y Elementos de Protección Personal",
          description: 'Guantes, Mascarillas, Gafas, Etc.'
        },
      ],
    };
  }

  render() {
    return (
      <>
        <section className="section bg-services" id="products">
          <Container>
            <ServicesHeader title="Consulta aquí nuestros productos" />

            <Row className="mt-1 pt-1">
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4" style={{ minHeight: '475px'}}>

                  <div style={{ display: 'flex', alignItems: 'center', minHeight: '170px' }}>
                    <div className="services-icon bg-soft-primary" style={{ marginRight: '40px' }}>
                      <img src={`../assets/images/services/${item.id}.png`}
                        alt={item.id} style={{ width: '84px', height: '84px', borderRadius: "50%" }} /> 
                    </div>

                    <h5>{item.title}</h5>
                  </div>

                  <div className="services-detail-box p-1 mt-4" style={{ minHeight: '150px'}}>
                    <p className="text-light mt-3">{item.description}</p>
                  </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
