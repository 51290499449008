import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import Slider from "react-slick";


import Img1 from '../assets/images/testi-img/img-1.png';
import Img2 from '../assets/images/testi-img/img-2.png';
import Img3 from '../assets/images/testi-img/img-3.png';
import Img4 from '../assets/images/testi-img/img-4.png';


import SectionTitle from "./SectionTitle";

const slideitems = [
  {
    id: 100,
    img: Img1,
    description: 'Proporcionamos entrenamiento especializado en técnicas de trabajo y rescate en alturas, equipada con infraestructura y equipos de última generación para simular condiciones reales. Los programas se adaptan a diferentes industrias y están enfocados en la seguridad, habilidad y confianza.'
  },
  {
    id: 101,
    img: Img2,
    description: 'Contamos con un entorno controlado y seguro con andamios y equipos de última generación. Nuestros cursos están diseñados para equipar a los participantes con habilidades prácticas esenciales y conocimientos técnicos, garantizando que puedan realizar su trabajo con la mayor seguridad posible.  Utilizando nuestra infraestructura de andamios podemos simular un ambiente de trabajo realista y desafiante, donde nuestros clientes pueden practicar y perfeccionar sus habilidades bajo la supervisión de instructores certificados y con una gran experiencia en la industria.'
  },
  {
    id: 103,
    img: Img3,
    description: 'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.'
  },
  {
    id: 104,
    img: Img4,
    description: 'The European languages are members of the Same family Their separate existence is a myth For science, music, sport, etc, europe convals d feugiat egdgfet cursus tellus their languages common words.'
  },
];

export default class Practices extends Component {

  render() {

    var slidesettings = {
      dots: true,
      speed: 600,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: false,
      dotsClass: "slick-dots slick-thumb carousel-indicators",
      customPaging: function (i) {
        return (
          <img src={process.env.PUBLIC_URL + '/images/img-' + (i + 1) + '.jpg'} alt="" className=" testi-img img-fluid rounded mx-auto d-block" />
        );
      },
    };

    const clientslides = slideitems.map((slideitem, clientkey) => {
      return (
        <div className="carousel-item" key={slideitem.id}>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="client-box mt-4">
                <p className="line-height_1_6 text-light">{slideitem.description}</p>


              </div>
            </Col>
            <Col lg={6}>
              <div className="text-center mt-4">
                <img src={slideitem.img} className="img-intallations img-fluid" alt="" />
              </div>
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <>
        <section className="section">
          <Container>
            <Row>
              <Col lg={12}>
                <SectionTitle 
                  title="Prácticas e instalaciones" 
                  light={false}
                />
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg="12">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <Slider {...slidesettings}>
                      {clientslides}
                    </Slider>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

      
      </>
    );
  }
}
