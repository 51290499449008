import React from 'react';
import { Col, Row } from "reactstrap";
import ClientLogo from './ClientLogo';

import Client1 from '../assets/images/clients/logo-bomberos-150x150.png';
import Client2 from '../assets/images/clients/logo-consumar-150x150.png';
import Client3 from '../assets/images/clients/logo-decameron-panaca-150x150.png';
import Client4 from '../assets/images/clients/logo-defensa-civil-150x150.png';
import Client5 from '../assets/images/clients/logo-edeq-150x150.png';
import Client6 from '../assets/images/clients/logo-meals-150x150.png';
import Client7 from '../assets/images/clients/logo-panaca-150x150.png';
import Client8 from '../assets/images/clients/logo-parque-nacional-150x150.png';

const clientLogos = [
  { src: Client1, alt: "Bomberos" },
  { src: Client2, alt: "Consumar" },
  { src: Client3, alt: "Decameron Panaca" },
  { src: Client4, alt: "Defensa Civil" },
  { src: Client5, alt: "Edeq" },
  { src: Client6, alt: "Meals" },
  { src: Client7, alt: "Panaca" },
  { src: Client8, alt: "Parque Nacional" }
];

const SectionClienteList = () => {
  return (
    <Row className="mt-5 pt-4">
      {clientLogos.map((client, index) => (
        <Col lg={4} key={index}>
          <ClientLogo src={client.src} alt={client.alt} />
        </Col>
      ))}
    </Row>
  );
};

export default SectionClienteList;
