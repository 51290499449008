import React, { Component } from "react";
import { Row, Col } from 'reactstrap';

class Footer_link extends Component {
  render() {
    return (
      // Footer Link start
      <Row>
        <Col lg={12}>
          <div className="text-center">
            <a href="https://takesolut.com" target="_blank"  rel="noreferrer noopener" className="brillo">
              <p className="text-muted mb-0">{(new Date().getFullYear())}{" "} © Develop by TakeSolut </p> 
            </a>
          </div>
        </Col>
      </Row>
      //   Footer Link End
    );
  }
}

export default Footer_link;
