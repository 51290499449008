import React, { Component } from "react";
import HomeUrl from '../assets/images/home-border.png';

class ImgSeparator extends Component {

  render() {
    return (
      <>
        <img src={HomeUrl} height="15" alt="" />
      </>
    );
  }
}

export default ImgSeparator;
