import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import ServicesHeader from "./ServicesHeader";

export default class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service7',
          icon: 'mdi-youtube',
          title: "Trabajo seguro en alturas",
          description: '',
          text: [
            'TRABAJADO AUTORIZADO: Dirigido a personal que realice tránsito vertical – horizontal. 32 horas',
            'NIVEL REENTRENAMIENTO: Dirigido a personal que realice tránsito vertical – horizontal. minimo 8 horas',
            'NIVELES JEFE DE AREA (ADMINISTRATIVO): Dirigido a personal que realice tránsito vertical, sobre piso o plataformas. 8 horas',
            'NIVEL COORDINADOR DE TRABAJO SEGURO EN ALTURAS.  Dirigido a personal que realice tránsito vertical – horizontal. Y firme permisos para trabajo en alturas. 80 horas',
            'VENTA DE EQUIPOS PARA TRABAJO SEGURO EN ALTURAS'
          ]
        },
      ],
    };
  }


  render() {
    return (
      <>
        <section className="section bg-services" id="courses">
          <Container>
            <ServicesHeader title="Consulta aquí nuestros cursos" subtitle="CENTRO DE FORMACIÓN INTEGRAL EN EMERGENCIAS, RESCATE Y SALUD LABORAL" />
            
            <Row className="mt-1 pt-1">
              {this.state.services.map((item, key) => (
                <Col lg={12} key={key}>
                  <div className="services-box p-4 mt-4">

                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '110px' }}>

                      <div className="services-icon bg-soft-primary" style={{ marginRight: '50px' }}>
                        <img src={`../assets/images/services/${item.id}.png`}
                          alt={item.id} style={{ width: '84px', height: '84px', borderRadius: "50%" }} />
                      </div>

                      <h5>{item.title}</h5>
                    </div>
                    {
                      item.description.length > 0 ? (
                        <p className="text-muted mt-3">{item.description}</p>
                      ) : null
                    }

                    <div className="services-detail-box p-1 mt-4">
                      <div className="mt-3">
                        <ul>
                          {item.text?.map((text, index) => (
                            <li key={index * 10} className="text-light mt-3">{text}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <a href="https://cursos.seguridadallimite.com/#/student/inscription" className="btn btn-primary amarillo mr-3">Inscríbete</a>
                    </div>

                  </div>
                </Col>
              ))}


            </Row>
          </Container>
        </section>
      </>
    );
  }
}
