import React from 'react';

const ClientLogo = ({ src, alt }) => {
  const logoStyle = {
    borderRadius: '25%',
    border: '5px solid white',
    overflow: 'hidden',
    width: 'fit-content',
    margin: 'auto',
    backgroundColor: 'white'
  };

  return (
    <div className="client-images mt-4" style={logoStyle}>
      <img src={src} alt={alt} className="mx-auto img-fluid d-block" />
    </div>
  );
};

export default ClientLogo;
