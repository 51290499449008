import React, { Component } from "react";

import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

import Feature from '../assets/images/features/img-3.png';
import ImgSeparator from "../component/ImgSeparator";
import emailjs from 'emailjs-com';


export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requestType: 'personal',
      emailSent: false,
      emailSentMessage: '',
    };
  }

  handleRequestTypeChange = (e) => {
    this.setState(prevState => ({
      ...prevState,
      requestType: e.target.value
    }));
  };

  sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qfkyacx', 'template_0yz8q1e', e.target, '3yBrocKoF55x5oiCg')
      .then((result) => {
          this.setState({
            emailSent: true, 
            emailSentMessage: 'Correo enviado exitosamente!'
          });
          setTimeout(() => this.setState({
            emailSent: false, 
            emailSentMessage: ''
          }), 5000);
      }, (error) => {
          console.log(error.text);
          this.setState({
            emailSent: true, 
            emailSentMessage: 'Error al enviar el correo.'
          });
      });
  }

  render() {
    const { requestType, emailSent, emailSentMessage } = this.state;


    return (
      <>
        <section className="section" id="contact">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                  <h3 className="title-heading text-light mt-4">Solicita información!</h3>

                  <ImgSeparator />

                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg={6}>
                <div className="mt-4 home-img text-center d-none d-lg-block">
                  <img src={Feature} className="img-contact img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="custom-form mt-4">
                  <div id="message"></div>
                  <Form method="post" name="contact-form" id="contact-form" onSubmit={this.sendEmail}>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Tipo de solicitud</Label>
                          <Input
                            type="select"
                            name="requestType"
                            id="requestType"
                            className="form-control"
                            value={requestType}
                            onChange={this.handleRequestTypeChange}
                          >
                            <option value="empresa">Empresa</option>
                            <option value="personal">Personal</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    {requestType === 'empresa' && (
                      <>
                        <Row>
                          <Col lg={12}>
                            <FormGroup className="mt-3">
                              <Label className="contact-lable">Nombre de la empresa</Label>
                              <Input name="name" id="name" className="form-control" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <FormGroup className="mt-3">
                              <Label className="contact-lable">Nombre completo de la persona contacto</Label>
                              <Input name="name" id="name" className="form-control" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}

                    {requestType === 'personal' && (
                      <>
                        <Row>
                          <Col lg={6}>
                            <FormGroup className="mt-3">
                              <Label className="contact-lable">Nombre</Label>
                              <Input name="name" id="name" className="form-control" type="text" />
                            </FormGroup>
                          </Col>
                          <Col lg={6}>
                            <FormGroup className="mt-3">
                              <Label className="contact-lable">Apellido</Label>
                              <Input name="name" id="lastname" className="form-control" type="text" />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Telefono de contacto</Label>
                          <Input name="telefonocontacto" id="telefonocontacto" className="form-control" type="phone" maxLength={10} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Correo electrónico</Label>
                          <Input name="email" id="email" className="form-control" type="text" maxLength={80} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Mensaje</Label>
                          <Input type="textarea" name="message" id="message" rows="5" className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="mt-3 text-right">
                        <Input id="submit" name="send" color="primary" className="submitBnt btn btn-primary btn-round" value="Solicitar información" type="submit" style={{ width: 'auto', color: '#fff' }} />{' '}
                        <div id="simple-msg"></div>

                        <div id="simple-msg">
                          {emailSent && <p>{emailSentMessage}</p>}
                        </div>
                        
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
