import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

//import images
import Img1 from '../assets/images/features/imagen-certificado.png';

export default class QualityPolicy extends Component {
  render() {
    return (
      <>
        <section className="section bg-light bg-features">
          <Container>
            <Row className="align-items-center">
              <Col lg={5}>
                <div className="mt-4 home-img">
                  <img src={Img1} className="img-certificate img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6} className="offset-lg-1">
                <div className="mt-4">
                  <h2>Política de calidad</h2>
                  <p className="mt-4 text-muted">SEGURIDAD AL LIMITE SAS, presta servicios de consultoría y asesoramiento en
seguridad industrial y formación de trabajo seguro en alturas, dando cumplimiento
a la norma NTC 6072, en proceso de certificación, seguridad y salud en el trabajo,
Medio ambiente, riesgos químicos y planes de gestión SST cumpliendo los requisitos
legales aplicables. SEGURIDADLIMITE SAS tiene un compromiso con la satisfacción
de nuestros clientes y mejora continua, estando a la vanguardia con la tecnología,
generando rentabilidad en benecio de la sociedad y la empresa mejorando la
eficacia de nuestros procesos</p>

                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
      </>
    );
  }
}
