import React, { Component } from "react";

import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";

import ImgSeparator from "../component/ImgSeparator";

import Img1 from '../assets/images/users/24-225x300.png';

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          id: 1,
          img: Img1,
          name: 'Cesar Pineda',
          nickname: 'Entrenador',
          description: ['Profesional en seguridad y salud laboral.',
                        'Técnico urgencias médicas.',
                        'Rescatista y entrenador trabajo en alturas'
                      ]
        },
      ],
    };
  }

  render() {
    var temsettings = {
      autoplay: true,
      dots: true,
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };

    const teamslides = this.state.teams.map((team, teamindex) => {
      return (
        <div className="team-box p-3" key={teamindex}>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h5 className="mt-2">{team.name}</h5>
                <p className="f-20 text-info">{team.nickname}</p>
                
                <ul className="text-muted mt-3">
                    {team.description.map((desc, index) => (
                        <li key={index}>{desc}</li>
                    ))}
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className="mt-4">
                <img src={team.img} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </div>
      );
    });
    return (
      <>
        <section className="section bg-light bg-cta">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <div className="text-center">
                  <h2>QUIENES SOMOS <span className="text-white">SEGURIDAD AL LIMITE</span></h2>
                  <p className="tam text-white mt-4">Somos una empresa fundada en el año 2015 con un grupo de profesionales especialistas
                  en seguridad y salud en el trabajo, que ofrece capacitación, asesoría y consultoría</p>
                  
                  <p className="tam text-white mt-4">Técnicas en seguridad indistrial, medicina proventiva, medicina laboral, gestion ambiental y 
                  gestión de calidad, gracias a nuestros aliados logramos de esta manera dar acompañamiento en todos los
                  procesos requeridos por las empresas.</p>

                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
        <section className="section pt-0" id="team">
          <Container>
            <Row>
              <Col lg="12">
                <div className="espace title-box text-center">
                  <h3 className="title-heading text-light mt-4 my-4">Conoce nuestro personal </h3>  
                  <ImgSeparator />
                </div>
              </Col>
            </Row>
            <Row className="mt-1 pt-1">
              <Col lg="12">
                <div className="team-carousel">
                  <Slider {...temsettings}>
                    {teamslides}
                  </Slider>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
      </>
    );
  }
}
