import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import { Link } from "react-router-dom";

import ServicesHeader from "./ServicesHeader";

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service1',
          icon: 'mdi-google',
          title: "Asesoria e impementación de Sistemas de Gestión en seguridad y salud en el trabajo",
          description: "",
          text: [
            "Diseño y documentación del SG-SST e implementación del SG-SST.",
            "Diseño, documentación e implementación de planes de emergencia.",
          ],
          expansiveText: [
            "Entrenamiento de brigadas de emergencia nivel básico, medio y avanzado.",
            "Planeación y ejecución del simulacro.",
            "Investigación de accidentes de trabajo.",
            "Diseño e implementación de programas de prevención y protección contra caídas"
          ]
        },
        {
          id: 'service2',
          icon: 'mdi-instagram',
          title: "Inspección de seguridad y planes de emergencia",
          description: '',
          text: [
            'Evaluación inicial del Sistema de Gestión de la -Seguridad y Salud en el Trabajo, SG-SST',
            'Diseño de Política en Seguridad y Salud en el Trabajo.',
          ],
          expansiveText: [
            'Diseño de Reglamento de Higiene y Seguridad Industrial.',
            'Diseño de Matriz para la identicación de peligros, evaluación y valoración de los riesgos',
            'Conformación e Implementación de comités de COPASST y Convivencia',
            'Identicación de Amenazas y análisis de la vulnerabilidad.',
            'Planeación y ejecución de Simulacros.',
            'Valoración y evaluación de riesgos.',
            'Capacitación de brigadas de emergencias',
            'Diseño y documentación de planes de emergencias.',
            'Diseño y documentación de plan de evacuación.',
            'Asesoría en señalización y demarcación de áreas de trabajo.'
          ]
        },
        {
          id: 'service3',
          icon: 'mdi-youtube',
          title: "Investigación de accidentes laborales",
          description: '',
          text: [
            'Investigar los incidentes y accidentes de trabajo, dentro de los 15 días siguientes a la ocurrencia del evento.',
            'Revisar hechos y evidencias.',
          ],
          expansiveText: [
            'Determinar las causas inmediatas (actos y condiciones subestándar) y causas básicas (factores del trabajo y personales).',
            'Preparar el informe de la investigación, según lo descrito en el Capítulo II de la Resolución 1401 de 2007 (Ministerio de Trabajo).'
          ]
        },
        {
          id: 'service4',
          icon: 'mdi-youtube',
          title: "Formación y certificación en Brigadas de Emergencia",
          description: ''
        },
        {
          id: 'service5',
          icon: 'mdi-youtube',
          title: "Señalización y Demarcación de áreas de trabajo",
          description: '',
          text: [
            'La necesidad de las empresas de proteger integralmente la salud de sus trabajadores se expone en la Resolución 2400 de mayo 22 de 1979, artículos 202 al 204 del Ministerio de Trabajo y Seguridad Social.',
          ],
          expansiveText: [
            'Señales: Señales de prohibición - Señales de acción de mando o protección - Señales de prevención o advertencia - Señales de seguridad o informativa - Señales complementarias o auxiliares',
            'Señales de piso - Cintas para demarcación - Conos, canecas y barricadas - Señales acústicas.',
            'Asesoria e implementacion en demarcacion adecuada a cada area de trabaj0.'
          ]
        },
        {
          id: 'service6',
          icon: 'mdi-youtube',
          title: "Inspecciones de Seguridad y plan de emergencia",
          description: '',
          text: [
            'Evaluación inicial del Sistema de Gestión de la -Seguridad y Salud en el Trabajo, SG-SST',
            'Diseño de Política en Seguridad y Salud en el Trabajo.',
          ],
          expansiveText: [
          'Diseño de Reglamento de Higiene y Seguridad Industrial.',
          'Diseño de Matriz para la identicación de peligros, evaluación y valoración de los riesgos',
          'Conformación e Implementación de comités de COPASST y Convivencia',
          'Identicación de Amenazas y análisis de la vulnerabilidad.',
          'Planeación y ejecución de Simulacros.',
          'Valoración y evaluación de riesgos.',
          'Capacitación de brigadas de emergencias',
          'Diseño y documentación de planes de emergencias.',
          'Diseño y documentación de plan de evacuación.',
          'Asesoría en señalización y demarcación de áreas de trabajo.'
          ]
        },
        {
          id: 'service8',
          icon: 'mdi-youtube',
          title: "Tareas de alto riesgo",
          description: 'Plan para actividades como:',
          expansiveText: [
            'Trabajo en caliente: - Identicación de trabajos en caliente.- identicación de peligros.- Medias preventivas.',
            'Espacios connados: - Identicar espacios confinados. - Denición de “entrar físicamente - Combinaciones peligrosas. - Ambiente peligroso.- Peligros de espacios confinados'
          ]
        },
        {
          id: 'service9',
          icon: 'mdi-youtube',
          title: "Planes de seguridad vial",
          description: '',
          text: [
            'La Seguridad Vial en el entorno laboral',
            'Factores de riesgo.',
            'Factor Humano: Atención, distracciones, velocidad, alcohol, fatiga.',
          ],
          expansiveText: [
            'Factor Vehículo: Seguridad Activa y Pasiva',
            'Factor Vía: Seguridad Activa y Pasiva. Factores ambientales.',
          ]
        },
      
      ],
    };
  }

  toggleShowMore = (index) => {
    this.setState(prevState => {
      const services = [...prevState.services];
      services[index] = {
        ...services[index],
        showMore: !services[index].showMore
      };
      return { services };
    });
  };


  render() {
    return (
      <>
        <section className="section bg-services" id="services">
          <Container>
            <ServicesHeader title="Consulta aquí nuestros servicios" />

            <Row className="mt-1 pt-1">
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-3 mt-4" style={{ minHeight: "475px" }}>

                    <div style={{ display: 'flex', alignItems: 'center', minHeight: '170px' }}>

                      <div className="services-icon bg-soft-primary" style={{ marginRight: '50px' }}>
                        <img src={`../assets/images/services/${item.id}.png`}
                          alt={item.id} style={{ width: '84px', height: '84px', borderRadius: "50%" }} />
                      </div>

                      <h5>{item.title}</h5>
                    </div>
                    {
                      item.description.length > 0 ? (
                        <p className="text-muted mt-3">{item.description}</p>
                      ) : null                      
                    }

                    <div className="services-detail-box p-1 mt-4">

                      <div className="mt-2">
                          <ul>
                            {item.text?.map((text, index) => (
                              <li key={index * 10} className="text-light mt-3">{text}</li>
                            ))}
                          </ul>
                      </div>

                      <div className="mt-3">
                        <Link
                          to="#"
                          className="text-muted f-16"
                          onClick={() => this.toggleShowMore(key)}
                        >
                          {item.showMore ? 'Leer menos' : 'Leer más...'}
                          <i className={` mdi ${item.showMore ? 'mdi-minus' : 'mdi-arrow-right'} ml-1`}></i>
                        </Link>

                      </div>
                      
                      {item.showMore && (
                        <div className="mt-3">
                          <ul>
                            {item.expansiveText.map((text, index) => (
                              <li key={index} className="text-light mt-3">{text}</li>
                            ))}
                          </ul>
                        </div>
                      )}

                    </div>

                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </>
    );
  }
}
