import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import FooterLink from "../Footer/Footer_link";

import LogoDark from '../../assets/images/logo-dark.png';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        {
          id: 1,
          title: "Horario De Atención administrativo",
          child: [
            { title: "Lunes a viernes 08:00 am a 12:00 pm y de 2:00 pm a 6:00 pm" },
            { title: "Sabados de 8:00 am a 12:00 pm"},
          ],
        },
        {
          id: 2,
          title: "Nuestra Ubicación",
          child: [
            { title: "Km. 2 Vía La Tebaida"},
            { title: "Armenia Quindío" },
            { title: "Colombia" }
          ],
        },
      ],
    };
  }

  render() {
    return (
      <>
        <footer
          className="section bg-light bg-footer pb-5"
        >
          <Container>
            <Row>
              <Col lg={4}>
                <div className="footer-info mt-4">
                  <img src={LogoDark} alt="" height="67"/>
                  <p className="text-white mt-4 mb-2">+57 322 593 6279</p>
                  <p className="text-white mt-4 mb-2">+57 311 626 3300</p>
                  <p className="text-white mt-4 mb-2">seguridadlimite@hotmail.com</p>
                </div>
              </Col>
              <Col lg={4}>
                <Row className="pl-0 md-lg-5">

                  {this.state.links.map((item, key) => (
                    <Col lg={6} key={key} style={{ maxWidth: "300px" }}>
                      <div className="mt-4">
                        <h5 className="f-20" style={{ minHeight: "20px" }}>{item.title}</h5>
                        <ul className="list-unstyled footer-link mt-3">
                          {item.child.map((linkItem, key) => (
                            <p key={key} className="text-white mt-4 mb-2">{linkItem.title}</p>                                
                          ))}
                        </ul>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col lg={2}>
                <div className="mt-4">
                  <h5 className="f-20" style={{ minHeight: "20px" }}>Redes Sociales</h5>
                  <div className="subscribe mt-4 pt-1">
                    <div className="team-social mt-4 pt-2">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <a href="https://www.facebook.com/seguridaallimte/" className="text-reset" target="_blank" rel="noreferrer noopener"><i className="mdi mdi-facebook"></i></a>
                        </li>{" "}
                        <li className="list-inline-item">
                        <Link to="#" className="text-reset"><i className="mdi mdi-twitter"></i></Link>
                        </li>{" "}
                        <li className="list-inline-item">
                        <Link to="#" className="text-reset"><i className="mdi mdi-instagram"></i></Link>
                        </li>{" "}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
              </Row>
              <hr className="my-5" />
              {/* Render Footer Link End */}
              <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </>
    );
  }
}

export default Footer;
