import React from 'react';
import { Col, Row } from 'reactstrap';
import ImgSeparator from './ImgSeparator';

const ServicesHeader = ({ title, subtitle }) => (
    <Row>
        <Col lg="12">
            <div className="title-box text-center">
                <h3 className="title-heading text-light mt-4">{title}</h3>
                {subtitle &&
                    <p className="text-muted f-17 mt-3">{ subtitle }</p>
                }

                <ImgSeparator />
            </div>
        </Col>
    </Row>
);

export default ServicesHeader;
