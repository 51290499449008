import React, { Component } from "react";

import Navbar from "../../component/Navbar/NavBar";

import Section from "./Section";
import Courses from "../../component/Courses";
import Services from "../../component/Services";
import Products from "../../component/Products";
import QualityPolicy from "../../component/QualityPolicy";
import Team from "../../component/Team";
import Clients from "../../component/Practices";
import Contact from "../../component/Contact";
import Footer from "../../component/Footer/Footer";
import Practices from "../../component/Clients";

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "home", navheading: "Inicio" },
        { id: 2, idnm: "courses", navheading: "Cursos" },
        { id: 3, idnm: "services", navheading: "Servicios" },
        { id: 4, idnm: "products", navheading: "Productos" },
        { id: 5, idnm: "team", navheading: "Equipo" },
        { id: 6, idnm: "clients", navheading: "Clientes" },
        { id: 7, idnm: "contact", navheading: "Contacto" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "navbar-light",
      fixTop : true
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "navbar-light", imglight: true });
    }
  };

  render() {
    return (
      <>
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
            top={this.state.fixTop}
          />

          <Section />

           <Courses />

           <Services />

          <Products />

          <Team />

          <QualityPolicy />

          <Clients />

          <Practices />

          <Contact />

          <Footer />
      </>
    );
  }
}
export default HomePage;
  